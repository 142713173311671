import { type Distributor } from './Distributor';
import { type EntitySetting } from './EntitySetting';

export const supportedFeatureToggles = [
  'enable_invoices_page',
  'enable_customers_page',
  'show_currencies_exchange_rates',
  'enable_shipments_page',
  'enable_certificates_page',
  'show_share_quote_via_email',
  'enable_shipments_duty_cost',
  'enable_column_filters',
  'enable_column_sorting',
  'enable_insights_page',
  'enable_quotes',
  'enable_policies',
  'enable_claims',
  'enable_storage',
  'enable_duplicate_quote',
  'enable_draft_certificate',
  'quote_details_page',
] as const;

export type FeatureToggle = (typeof supportedFeatureToggles)[number];

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  distributor: Distributor;
  has_access_to_platform: boolean;
  feature_toggles: { [K in FeatureToggle]: boolean };
  settings: EntitySetting[];
  avatar_color?: string;

  /**
   * @deprecated will be replaced by Auth0 and token based permissions.
   */
  firebase_uid: string;
  role?: string;
};
export const isUserType = (user: unknown): user is User => {
  return (
    user !== null &&
    typeof user === 'object' &&
    'id' in user &&
    'first_name' in user &&
    'last_name' in user &&
    'email' in user &&
    'distributor' in user &&
    'feature_toggles' in user &&
    'settings' in user &&
    'firebase_uid' in user &&
    'role' in user
  );
};
