import { Table, Typography } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';

import { type Claim } from '../../../model/Claim';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { useClaimsTableColumns } from '../../claims/ClaimsTable/use-claims-table-columns';
import labels from '../../labels';
import { policiesQueries } from '../../policies/network/queries';
import { PageSection } from '../PageSection/PageSection';
import styles from './PolicyClaims.module.scss';

export const PolicyClaims = ({ policyId }: { policyId?: string }) => {
  const { navigateToClaim, navigateToClaimFlow } = useAppNavigation();
  const columns = useClaimsTableColumns();

  const { data: policy } = useSuspenseQuery(
    policiesQueries.details({ policyId }),
  );
  const { data: claims } = useSuspenseQuery(
    policiesQueries.policyClaims({ policyId }),
  );

  const hasClaims = (claims ?? []).length > 0;

  if (!policy) {
    return null;
  }

  return (
    <PageSection data-testid="policy-claims">
      <div className={styles.header}>
        <Typography level="h4">
          Claims {hasClaims ? `(${claims?.length})` : ''}
        </Typography>
        <Button
          width="fixed"
          variant="secondary"
          onPress={() =>
            navigateToClaimFlow({ certificateId: policy?.certificate_id })
          }
          label="File a Claim"
        />
      </div>
      {hasClaims ? (
        <div className={styles.claimsTableWrapper}>
          <Table<Claim>
            rows={claims ?? []}
            columns={columns}
            disableSelectionOnClick
            density="comfortable"
            getRowId={(row) => row.id}
            hideFooter={true}
            showToolbar={false}
            disableVirtualization={true}
            onRowClick={({ id }) => navigateToClaim(id)}
          />
        </div>
      ) : (
        <Typography level="base" variant="input">
          No claims were submitted against this {labels.terms.policy.singular}.
        </Typography>
      )}
    </PageSection>
  );
};
