import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../context/PlatformContext';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { QuotesTable } from '../components/QuotesTable/QuotesTable';

export const QuotesPage = () => {
  useTracking();
  const { isWtw } = usePlatform();

  return (
    <SectionLayout
      title="Quotes"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="quotes-page"
      actions={
        <LinkButton
          size="small"
          data-testid="get-quote-button"
          href="/quotes/create"
          width="auto"
          label="Get A Quote"
        />
      }
    >
      <QuotesTable />
    </SectionLayout>
  );
};
