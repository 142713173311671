import { useCallback, useEffect, useState } from 'react';
import {
  Date,
  Link,
  Tab,
  TabContent,
  Tabs,
  Typography,
} from '@breeze-ai/ui-library';
import { Spinner } from '@breezeai-frontend/cargo-ui';

import { DISTRIBUTION_EMAIL } from '../../../app-constants';
import { type Claim } from '../../../model/Claim';
import {
  getClaimById,
  getClaimFiles,
} from '../../../network/apis/claims/claims';
import { type FileResponse } from '../../../network/apis/claims/types';
import { type ErrorResponse } from '../../../network/apis/types';
import { useRouteParams } from '../../../router/router-hooks';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useTracking } from '../../../utils/snowplow/hooks';
import {
  type Asset,
  AssetsList,
} from '../../components/FileList/AssetsList/AssetsList';
import { PageSection } from '../../components/PageSection/PageSection';
import { useSectionError } from '../../components/Section/SectionContext';
import { SectionLayout } from '../../components/Section/SectionLayout';
import { ClaimContactDetails } from '../ClaimContactDetails/ClaimContactDetails';
import { ClaimCosts } from '../ClaimCosts/ClaimCosts';
import { ClaimEventDetails } from '../ClaimEventDetails/ClaimEventDetails';
import { ClaimStatusBadge } from '../ClaimStatusBadge/ClaimStatusBadge';
import styles from './ClaimDetailsPage.module.scss';

type ClaimRouteParams = {
  claimId: string;
};

export const ClaimDetailsPage = () => {
  useTracking();

  const { params } = useRouteParams<ClaimRouteParams>();
  const { navigateToSection } = useAppNavigation();
  const { setError } = useSectionError();
  const [loading, setLoading] = useState<boolean>();
  const [claim, setClaim] = useState<Claim>();
  const [claimFiles, setClaimFiles] = useState<FileResponse[]>([]);

  const { claimId } = params;
  const claimAssets = claimFiles?.map<Asset>((file) => ({
    name: file.file_name,
    type: file.file_type,
    path: file.url,
    creationDate: file.created_time,
  }));

  const fetchClaimDetails = useCallback(
    async (claimId: string) => {
      try {
        const details = await getClaimById(claimId);
        const files = await getClaimFiles(claimId);
        setClaim(details);
        setClaimFiles(files);
      } catch (e) {
        reportException(e);
        setError(e as ErrorResponse);
      }
    },
    [setError],
  );

  useEffect(() => {
    if (!claim && claimId) {
      setLoading(true);
      fetchClaimDetails(claimId).finally(() => setLoading(false));
    }
  }, [claim, claimId, fetchClaimDetails]);

  return loading ? (
    <Spinner />
  ) : (
    // TODO - Convert to use new section layout
    <SectionLayout
      className={styles.claimsDetailsPage}
      contentClassName={styles.contentWrapper}
      testId="claim-details-page"
      title={
        <div className={styles.pageTitle} role="page-header">
          <Typography
            level="h2"
            prefixIcon="chevron-left"
            clickable
            onClick={() => navigateToSection('claims')}
          />
          <div className={styles.header}>
            <div className={styles.title}>
              <Typography level="h2">Claim {claim?.claim_number}</Typography>
              {claim && <ClaimStatusBadge claim={claim} />}
            </div>
            {claim && (
              <Typography block level="subtext" variant="input">
                Created on{' '}
                <Date
                  value={claim?.reported_time}
                  typographyProps={{ level: 'subtext' }}
                />
              </Typography>
            )}
          </div>
        </div>
      }
    >
      {claim && (
        <Tabs>
          <Tab id="claim-details" title="Claim Details" role="details-tab" />
          <Tab id="documents" title="Documents" role="documents-tab" />

          <TabContent tabId="claim-details">
            <div className={styles.gridLayout}>
              <div className={styles.main}>
                <ClaimEventDetails claim={claim} linkToPolicy={true} />
                <ClaimContactDetails claim={claim} />
              </div>
              <div className={styles.aside}>
                <ClaimCosts claim={claim} />
              </div>
            </div>
          </TabContent>
          <TabContent tabId="documents">
            <PageSection className={styles.documents}>
              <AssetsList
                title={<Typography level="h4">Claim files</Typography>}
                assets={claimAssets}
              />
              <Typography block variant="input" className="pt-6">
                Want to share more files? Drop us an email{' '}
                <Link href={`mailto:${DISTRIBUTION_EMAIL}`} underline={false}>
                  {DISTRIBUTION_EMAIL}
                </Link>
              </Typography>
            </PageSection>
          </TabContent>
        </Tabs>
      )}
    </SectionLayout>
  );
};
