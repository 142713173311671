import { Suspense } from 'react';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { usePlatform } from '../../context/PlatformContext';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import labels from '../labels';
import { PoliciesTable } from './PoliciesTable/PoliciesTable';

const PoliciesPageContent = () => {
  useTracking();
  const { isWtw } = usePlatform();

  const title = capitalize(labels.terms.policy.plural);

  return (
    <SectionLayout
      title={title}
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="policies-page"
    >
      <PoliciesTable />
    </SectionLayout>
  );
};

export const PoliciesPage = () => {
  return (
    <Suspense fallback={<Spinner className="self-center" />}>
      <PoliciesPageContent />
    </Suspense>
  );
};
