import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  CancelChangesModal,
  CertificateModalLoadingState,
  ErrorMessage,
  UpdateCertificateModal,
} from '@breezeai-frontend/cargo-ui';
import { type FormApi, useForm } from '@tanstack/react-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';

import { useUserSetting } from '../../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type ConveyanceType } from '../../../../model/Shipment';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import { queryClient } from '../../../../providers/ReactQueryProvider';
import { RouteDetails } from '../../../components/RouteDetails/RouteDetails';
import { RouteDetailsInputsProvider } from '../../../components/RouteDetails/RouteDetailsInputProvider';
import { getDefaultInputValue } from '../../../components/RouteDetails/utils';
import { useCreateCertificate } from '../../certificateApiHooks';
import { certificateQueries } from '../../network/queries';
import { type CertificateModel } from '../../types';
import { CertificateReviewCard } from '../CertificateReviewCard/CertificateReviewCard';
import { CertificateStatusCard } from '../CertificateStatusCard/CertificateStatusCard';
import { useFormLogic } from '../useFormLogic';
import { useModalStore } from '../useModalStore';
import { getDefaultPortOrPlace } from '../utils';
import { ShipmentDetailsForm } from './ShipmentDetailsForm';
import { SubmissionButtons } from './SubmissionButtons';
import { type CertificateFormProps } from './types';

export function FormContent({
  form,
  isSubmitting,
  isUnderReview,
  certificate,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
  isSubmitting: boolean;
  isUnderReview: boolean;
  certificate: CertificateModel | undefined;
}) {
  const { isUpdate, isDuplicate } = useFormLogic();
  return (
    <form
      className="flex flex-1 flex-col md:flex-row p-3 h-full bg-white w-full gap-3 rounded-3xl min-h-fit"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <div className="flex h-full bg-surfaces-secondary rounded-2xl p-5 basis-96">
        <RouteDetails
          form={form}
          isDuplicate={isDuplicate}
          isUpdate={isUpdate}
        />
      </div>

      <div className="flex flex-col w-full">
        <ShipmentDetailsForm
          form={form}
          isUpdate={isUpdate}
          certificate={certificate}
        />
        <div className="flex flex-row gap-2 self-end mt-auto justify-end">
          <SubmissionButtons
            form={form}
            isUnderReview={isUnderReview}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
}

// TODO tidy component to make more readable
export function CertificateFlowForm({
  remountForm,
}: {
  remountForm: () => void;
}) {
  const preferredModeOfTransportCode: ConveyanceType =
    useUserSetting('preferred_mode_of_transport_code') ?? 'road';
  const defaultUserCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');
  const { getPayload, isUpdate, policyId } = useFormLogic();
  const navigate = useNavigate();

  const { data } = useSuspenseQuery(certificateQueries.details({ policyId }));

  const [status, setStatus] = useState(data?.quote?.status);

  const {
    isCancelChangesModalOpen,
    setIsCancelChangesModalOpen,
    isConfirmChangesModalOpen,
    setIsConfirmChangesModalOpen,
  } = useModalStore();

  const form = useForm<CertificateFormProps, typeof zodValidator>({
    // Required fields need to have default values
    defaultValues: {
      etd: data?.quote?.etd || '',
      eta: data?.quote?.eta || '',
      vesselName: data?.quote?.vessel_name || '',
      marksAndNumbers: data?.quote?.goods_marks || '',
      bookingReference: data?.quote?.external_reference || '',

      distributorId: data?.quote?.distributor_id || undefined,

      assuredName: {
        company_name: data?.customer?.name || '',
        id: data?.customer?.id || undefined,
      },

      origin: data?.quote
        ? getDefaultPortOrPlace(data?.quote, 'origin')
        : undefined,
      destination: data?.quote
        ? getDefaultPortOrPlace(data?.quote, 'destination')
        : undefined,
      primaryMot:
        data?.quote?.primary_transport_mode_code ||
        preferredModeOfTransportCode,

      placeOfLoading:
        data?.quote && data.quote.loading_place
          ? {
              type: 'place',
              place: {
                place_id: data.quote.loading_place.provider_place_uuid,
                description: data.quote.loading_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfLoadingMot: data?.quote?.loading_transport_mode_code || 'road',

      placeOfDelivery:
        data?.quote && data.quote.delivery_place
          ? {
              type: 'place',
              place: {
                place_id: data.quote.delivery_place.provider_place_uuid,
                description: data.quote.delivery_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfDeliveryMot: data?.quote?.delivery_transport_mode_code || 'road',

      cargoDescription: data?.quote?.commodity_external_description || '',
      cargoCurrency:
        data?.quote?.commodity_currency?.code ?? defaultUserCurrency,
      cargoValue: data?.quote?.commodity_value?.toString() || '',
      assuredAddress: {
        description: data?.customer_address?.full_address || '',
        place_id: data?.customer_address?.provider_place_uuid || '',
      },
      letterOfCredit: data?.quote?.letter_of_credit || '',
      issueDate: data?.issue_date || '',
    },
    onSubmit: ({ formApi }) => {
      const payload = getPayload(formApi);
      return mutate(payload);
    },

    validatorAdapter: zodValidator,
  });
  const {
    isPending: isMutating,
    isError: isMutatingError,
    mutate,
    reset,
  } = useCreateCertificate({
    isUpdate,
    options: {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['certificate', String(data.id)], data);
        const isUnderReview =
          data.quote?.status === 'pending_for_breeze_manual_review';

        navigate(
          {
            pathname: `/certificates/${isUnderReview ? 'update' : 'details'}/${
              data.certificate_id
            }/${data.id}`,
            search:
              isUnderReview || isUpdate
                ? ''
                : `?${createSearchParams({ ready: 'true' })}`,
          },
          {
            replace: true,
          },
        );

        setStatus(data.quote?.status);

        // Reset the form state
        remountForm();
      },
    },
  });

  return (
    <RouteDetailsInputsProvider
      // These are default values for the route details inputs
      originInputValue={getDefaultInputValue(form.getFieldValue('origin'))}
      destinationInputValue={getDefaultInputValue(
        form.getFieldValue('destination'),
      )}
      placeOfLoadingInputValue={
        form.getFieldValue('placeOfLoading')?.place?.description
      }
      placeOfDeliveryInputValue={
        form.getFieldValue('placeOfDelivery')?.place?.description
      }
    >
      <div className="flex flex-col w-full">
        <FormContent
          form={form}
          isUnderReview={
            status === QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW
          }
          isSubmitting={isMutating}
          certificate={data ?? undefined}
        />
        {status === QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW && (
          <CertificateStatusCard>
            <CertificateReviewCard
              exclusionReasons={data?.quote?.exclusion_reasons}
            />
          </CertificateStatusCard>
        )}
        {status === QuoteStatusEnum.DECLINED && (
          <CertificateStatusCard>
            <ErrorMessage
              type="SomethingWentWrong"
              title="Unable to provide cover for this shipment."
              details={[
                'This can happen if the cargo or journey falls outside of appetite for the insurer.',
                'Please feel free to contact us for more information.',
              ]}
            />
          </CertificateStatusCard>
        )}
      </div>

      <CertificateModalLoadingState
        isOpen={isMutating || isMutatingError}
        isError={isMutatingError}
        isLoading={isMutating}
        showExitButton={isMutatingError}
        onExit={reset}
        isUpdate={isUpdate}
      />
      <CancelChangesModal
        isOpen={isCancelChangesModalOpen}
        onOpenChange={setIsCancelChangesModalOpen}
        onDiscardChanges={() => void remountForm()}
        title="Are you sure you want to cancel changes?"
        subtitle="Your changes will be lost."
      />
      <UpdateCertificateModal
        isOpen={isConfirmChangesModalOpen}
        onOpenChange={setIsConfirmChangesModalOpen}
        onConfirmChanges={() => void form.handleSubmit()}
      />
    </RouteDetailsInputsProvider>
  );
}
