import { useMemo } from 'react';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Claim } from '../../../model/Claim';
import { ClaimStatusBadge } from '../ClaimStatusBadge/ClaimStatusBadge';

export const useClaimsTableColumns = (): TableColumn<Claim>[] => {
  const { isWtw } = usePlatform();
  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const certificatesEnabled = useFeatureToggle('enable_certificates_page');

  return useMemo(
    () =>
      [
        {
          ...getBaseColumnDefinition<Claim>('claim_number', 'Claim Number'),
          minWidth: 150,
          valueGetter: ({ row }: { row: Claim }) => row.claim_number,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>(
            'policy_certificate_id',
            `${certificatesEnabled ? 'Certificate' : 'Policy'} Number`,
          ),
          minWidth: 190,
          valueGetter: ({ row }: { row: Claim }) => row?.policy?.certificate_id,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>('reported_time', 'Submission Date'),
          minWidth: 180,
          type: 'date',
          valueGetter: ({ row }: { row: Claim }) => row.reported_time,
          renderCell: ({ row }: { row: Claim }) => (
            <DateCell
              format="SHORT_MONTH_NAME_DATE"
              value={row.reported_time}
              createdBy={
                row.created_by_user && {
                  name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                  avatarColor: row.created_by_user.avatar_color,
                }
              }
            />
          ),
          sortable: sortingEnabled,
        },
        isWtw
          ? {
              ...getBaseColumnDefinition('primary_assured', 'Primary Assured'),
              valueGetter: ({ row }: { row: Claim }) =>
                row.policy?.quote?.distributor?.legal_name,
              renderCell: ({ value }: { value: string }) => (
                <Tooltip content={String(value)}>
                  <div className="flex flex-row items-center gap-2 truncate w-full">
                    <TextCell>{value as string}</TextCell>
                  </div>
                </Tooltip>
              ),
              sortable: false,
              minWidth: 160,
            }
          : null,
        {
          ...getBaseColumnDefinition<Claim>(
            'customer_name',
            `${certificatesEnabled ? 'Named Assured' : 'Cargo Owner'}`,
          ),
          valueGetter: ({ row: { policy } }: { row: Claim }) =>
            policy?.customer?.name,
          minWidth: 180,

          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>('commodity_value', 'Cargo Value'),
          minWidth: 150,
          valueGetter: ({ row }: { row: Claim }) =>
            row?.policy?.quote?.commodity_value,
          renderCell: ({ row: { policy } }: { row: Claim }) => (
            <CurrencyCell
              value={policy?.quote?.commodity_value}
              currency={policy?.quote?.commodity_currency}
            />
          ),

          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>('claimed_amount', 'Claimed Amount'),
          type: 'number',
          minWidth: 180,
          valueGetter: ({ row }: { row: Claim }) => row.total_amount,
          renderCell: ({ row }: { row: Claim }) => (
            <CurrencyCell value={row.total_amount} currency={row.currency} />
          ),

          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>('paid_amount', 'Paid Amount'),
          type: 'number',
          minWidth: 150,
          valueGetter: ({ row }: { row: Claim }) => row.paid_amount,
          renderCell: ({ row }: { row: Claim }) => (
            <CurrencyCell value={row.paid_amount} currency={row.currency} />
          ),

          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition<Claim>('status', 'Status'),
          minWidth: 200,
          valueGetter: ({ row }: { row: Claim }) => row.status,
          renderCell: ({ row }: { row: Claim }) => (
            <ClaimStatusBadge claim={row} />
          ),
          sortable: sortingEnabled,
        },
      ].filter(Boolean) as TableColumn<Claim>[],
    [isWtw, sortingEnabled, certificatesEnabled],
  );
};
