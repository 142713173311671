import { Suspense, useState } from 'react';
import { FaCopy, FaTable } from 'react-icons/fa';
import {
  useNavigate,
  useParams,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import {
  Badge,
  CreateCertificateModal,
  type ErrorMessageProps,
  LinkButton,
  SectionLayout,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';

import {
  useAuthenticatedUser,
  useFeatureToggle,
} from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import {
  ErrorBoundaryFallback,
  parseError,
} from '../../../router/PlatformErrorBoundary/PlatformErrorBoundary';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { PolicyClaims } from '../../components/PolicyClaims/PolicyClaims';
import { PollingFileList } from '../../components/PollingFileList/PollingFileList';
import { QuoteCoverageDescriptions } from '../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import labels from '../../labels';
import { policiesQueries } from '../network/queries';
import { PolicyAdditionalClauses } from './PolicyAdditionalClauses/PolicyAdditionalClauses';
import styles from './PolicyDetailsPage.module.scss';
import { PolicyInsuranceDetails } from './PolicyInsuranceDetails/PolicyInsuranceDetails';
import { PolicyPriceBreakdown } from './PolicyPriceBreakdown/PolicyPriceBreakdown';
import { PolicyShipmentDetails } from './PolicyShipmentDetails/PolicyShipmentDetails';

export type PolicyRouteParams = {
  policyId: string;
};

export function PolicyDetailsPageErrorBoundary() {
  const error = useRouteError();
  const policyNotFoundError: ErrorMessageProps = {
    type: 'PageNotFound',
    title: 'Oops!',
    details: ['Sorry, the policy you are looking for does not exist.'],
  };

  const errorDetails = parseError(error).includes('PolicyNotFoundException')
    ? policyNotFoundError
    : undefined;

  const navigate = useNavigate();

  return (
    <ErrorBoundaryFallback
      type={errorDetails?.type}
      title={errorDetails?.title}
      details={errorDetails?.details}
      actions={[
        {
          variant: 'secondary',
          label: 'Back to Policies',
          leftIcon: <FaTable />,
          onPress: () => {
            navigate('/policies');
          },
        },
      ]}
    />
  );
}

export const PolicyDetailsPage = () => {
  useTracking();
  const enableClaims = useFeatureToggle('enable_claims');
  const [searchParams, setSearchParams] = useSearchParams();
  const isReady = searchParams.get('ready') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(isReady);

  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  const enableReferPolicyFlow = user?.settings.find(
    (s) => s.name === 'enable_refer_a_policy_flow',
  )?.value;

  const { policyId } = useParams<PolicyRouteParams>();

  const { data: policy, isLoading } = useQuery(
    policiesQueries.details({ policyId }),
  );

  const certificate_id = policy?.certificate_id;
  const { certificate_status, proof_of_cover_status } = policy ?? {};
  const title = `${capitalize(labels.terms.policy.singular)} ${
    certificate_id ?? ''
  }`;

  return (
    <>
      <SectionLayout
        data-testid="policy-details-page"
        tooltip={
          isWtw
            ? {
                trigger: <ActiveOpenCoverTooltip.Trigger />,
                content: <ActiveOpenCoverTooltip.Content />,
              }
            : undefined
        }
        actions={
          <LinkButton
            variant="secondary"
            label="Duplicate"
            leftIcon={<FaCopy />}
            width="auto"
            href={`/quotes/duplicate/${policy?.quote?.id}`}
          />
        }
        title={title}
        backLink="/policies"
        statusBadge={
          !isLoading &&
          !certificate_status &&
          !proof_of_cover_status && (
            <Badge variant="default" data-testid="documents-pending-badge">
              <Typography>Pending Cargo Owner Approval</Typography>
            </Badge>
          )
        }
      >
        <div className={styles.policiesDetailsPage}>
          <div className={styles.contentWrapper}>
            <div className={styles.wrapper}>
              <div className={styles.detailsLayout}>
                <div className={styles.policyDetails}>
                  <Suspense
                    fallback={
                      <div
                        style={{ height: '330px' }}
                        data-testid="skeleton-loader"
                        className={styles.skeletonLoader}
                      />
                    }
                  >
                    <PolicyShipmentDetails />
                  </Suspense>

                  <Suspense
                    fallback={
                      <div
                        style={{
                          height: '230px',
                        }}
                        data-testid="skeleton-loader"
                        className={styles.skeletonLoader}
                      />
                    }
                  >
                    <PolicyInsuranceDetails />
                  </Suspense>
                </div>
                <Suspense
                  fallback={
                    <div
                      style={{
                        flex: 0.2,
                      }}
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyPriceBreakdown />
                </Suspense>
              </div>

              <Suspense
                fallback={
                  <div
                    style={{ height: '200px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <PolicyAdditionalClauses />
              </Suspense>

              <Suspense
                fallback={
                  <div
                    style={{ height: '200px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <PollingFileList />
              </Suspense>

              {enableClaims && (
                <Suspense
                  fallback={
                    <div
                      style={{ height: '100px' }}
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyClaims policyId={policyId} />
                </Suspense>
              )}

              <Suspense
                fallback={
                  <div
                    style={{ height: '100px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <QuoteCoverageDescriptions policyId={policyId} />
              </Suspense>
            </div>
          </div>
        </div>
      </SectionLayout>
      <CreateCertificateModal
        data-testid="policy-creation-success-dialog"
        title={
          enableReferPolicyFlow ? 'Thank You!' : 'Your certificate is ready!'
        }
        description={
          enableReferPolicyFlow
            ? 'The policy was successfully referred.'
            : 'You can now view your certificate!'
        }
        isOpen={isModalOpen}
        onOpenChange={(value) => {
          setIsModalOpen(value);
          setSearchParams(undefined, {
            replace: true,
          });
        }}
        onExit={() =>
          setSearchParams(undefined, {
            replace: true,
          })
        }
      />
    </>
  );
};
