import { FaCheck } from 'react-icons/fa';
import { PiArchiveFill } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import {
  clsxMerge,
  SectionLayout,
  Spinner,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import invariant from 'tiny-invariant';

import { storageLocationQueries } from '../network/queries';
import { StorageCreationModal } from '../StorageCreationModal/StorageCreationModal';
import {
  StorageLocationFileTypeEnum,
  StorageLocationStatusEnum,
  type StorageRouteParams,
} from '../types';
import { Documents } from './Documents';
import { Metrics } from './Metrics';
import { Notes } from './Notes';
import { StorageDetailsActions } from './StorageDetailsActions';

// TODO use existing StatusBadge component from '../../../components/StatusBadge/StatusBadge';

const StatusBadge = ({ status }: { status: StorageLocationStatusEnum }) => (
  <div
    className={clsxMerge(
      status === StorageLocationStatusEnum.ACTIVE
        ? 'bg-system-green-100'
        : 'bg-system-grey-300',
      'flex gap-1 items-center px-2 py-1 rounded-full ml-2.5',
    )}
  >
    {status === StorageLocationStatusEnum.ACTIVE && (
      <FaCheck size={14} className="text-system-green-500" />
    )}
    {status === StorageLocationStatusEnum.ARCHIVED && (
      <PiArchiveFill size={14} className="text-system-grey-700" />
    )}
    <Typography level="subtext" color="primary" text="capitalize">
      {status}
    </Typography>
  </div>
);

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export const StorageDetailsPage = () => {
  const { policyId } = useParams<StorageRouteParams>();
  invariant(policyId, 'Policy ID is required');

  const { data } = useQuery(
    storageLocationQueries.details({
      policyId,
    }),
  );

  return (
    <SectionLayout
      data-testid="storage-details-page"
      title={data?.location_name ? capitalize(data.location_name) : ''}
      backLink="/storage"
      actions={data && <StorageDetailsActions {...data} />}
      statusBadge={data && <StatusBadge status={data.status} />}
    >
      {data ? (
        <div className="flex flex-col gap-6 w-full mt-4 sm:mt-0">
          <Metrics data={data} />
          <Documents
            type={StorageLocationFileTypeEnum.SURVEY_REPORT}
            data={data}
          />
          <Documents type={StorageLocationFileTypeEnum.OTHER} data={data} />
          <Notes notes={data?.notes} />
        </div>
      ) : (
        <Spinner />
      )}
      <StorageCreationModal />
    </SectionLayout>
  );
};
