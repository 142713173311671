import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { ClaimsTable } from './ClaimsTable/ClaimsTable';

export const ClaimsPage = () => {
  useTracking();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  if (!user) {
    return null;
  }

  return (
    <SectionLayout
      title="Claims"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="claims-page"
      actions={
        <LinkButton
          // TODO THEMING: Replace href with the enum
          href="/claims/start"
          data-testid="get-quote-button"
          variant="primary"
          width="auto"
          label="File A Claim"
        />
      }
    >
      <ClaimsTable />
    </SectionLayout>
  );
};
