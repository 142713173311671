import { FaCircleInfo } from 'react-icons/fa6';
import { Typography } from '@breeze-ai/ui-library';
import { Button, SectionLayout, Tooltip } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../context/auth/auth-hooks';
import { useTracking } from '../../utils/snowplow/hooks';
import styles from './InvoicesPage.module.scss';
import { InvoicesTable } from './InvoicesTable/InvoicesTable';
import { useInvoices } from './InvoicesTable/use-invoices';

export const InvoicesPage = () => {
  useTracking({
    eventFeature: 'invoice',
    eventSubfeature: 'list',
    eventAction: 'page_view',
    eventTrigger: 'invoices',
  });

  const user = useUser();

  const invoices = useInvoices();

  return (
    <SectionLayout
      title="Invoices"
      data-testid="invoices-page"
      tooltip={{
        trigger: (
          <Button
            customStyles="text-icons-default"
            data-testid="premium-breakdown-anchor"
            leftIcon={<FaCircleInfo size={16} />}
            variant="icon"
          />
        ),
        content: (
          <Tooltip
            placement="right"
            className={styles.wrapper}
            data-testid="premium-breakdown-tooltip"
          >
            <Typography className={styles.header}>
              Invoices are automatically generated and will appear here at the
              beginning of each calendar month. We will also send you a copy of
              the invoice over email.
              <br />
              <br />
              Please contact us if you would like to change your billing email
              address(es), which is currently set to: <br />
              <br />
              <ul className="pl-6">
                {user?.distributor.billing_email
                  ?.split(',')
                  .sort()
                  .map((email, index) => (
                    <li key={index} className="list-disc">
                      {email}
                      <br />
                    </li>
                  ))}
              </ul>
              <br />
              Payment is due within 30 days of invoice issue date. If payment is
              not received on time, affected policies may be cancelled.
              <br />
              <br />
              We accept payment by wire or bank transfer only.
            </Typography>
          </Tooltip>
        ),
      }}
    >
      <InvoicesTable invoices={invoices} />
    </SectionLayout>
  );
};
