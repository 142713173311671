import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../context/PlatformContext';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { CertificatesTable } from './CertificatesTable/CertificatesTable';

export const CertificatesPage = () => {
  useTracking();
  const { isWtw } = usePlatform();

  return (
    <SectionLayout
      title="Certificates"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="certificates-page"
      actions={
        <LinkButton
          size="small"
          data-testid="get-certificate-button"
          href="/certificates/create"
          width="auto"
          label="Get a Certificate"
        />
      }
    >
      <CertificatesTable />
    </SectionLayout>
  );
};
